import { combineReducers, configureStore } from "@reduxjs/toolkit";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import authReducer from "../store/reducers/authSlice";
import brandReducer from "./reducers/brandSlice";
import affiliateReducer from "../store/reducers/affiliateSlice";
import alertReducer from "../store/reducers/alertSlice";
import statisticReducer from "../store/reducers/statisticSlice";
import statusReducer from "../store/reducers/statusSlice";

const rootReducer = combineReducers({
  auth: authReducer,
  brand: brandReducer,
  affiliate: affiliateReducer,
  statistic: statisticReducer,
  status: statusReducer,
  alert: alertReducer,
});

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["brand", "affiliate", "statistic", "status"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});
const persistor = persistStore(store);

export { persistor };
export default store;
