import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import Home from "./Home";
import Meta from "../components/Meta";
import { Link } from "react-router-dom";

const Admin = () => {
  const auth = useSelector((state) => state?.auth);

  if (!auth.isAuth) {
    return <Home />;
  }

  // const [statistic, setStatistic] = useState({
  //   users: 0,
  //   products: 0,
  //   categories: 0,
  //   addresses: 0,
  // });

  // useLayoutEffect(() => {
  //   getStatistic().then((res) => setStatistic(res));
  // }, []);

  return (
    <>
      <Meta title="Главная" />
      <Row className="mb-3">
        <Col md={3}>
          <Link className="analytics flex-column align-items-center justify-content-center deals mb-3">
            <img
              src="/images/analitics/analitics-deals.svg"
              height={65}
              className="mb-4"
            />
            <h4 className="mb-1">15 541</h4>
            <span>Сделки в этом месяце</span>
          </Link>
        </Col>
        <Col md={3}>
          <Link className="analytics flex-column align-items-center justify-content-center ads mb-3">
            <img
              src="/images/analitics/analitics-ads.svg"
              height={65}
              className="mb-4"
            />
            <h4 className="mb-1">23 541</h4>
            <span>Активных объявлений</span>
          </Link>
        </Col>
        <Col md={3}>
          <Link className="analytics flex-column align-items-center justify-content-center tikets mb-3">
            <img
              src="/images/analitics/analitics-tickets.svg"
              height={65}
              className="mb-4"
            />
            <h4 className="mb-1">369</h4>
            <span>Неисполненные задачи</span>
          </Link>
        </Col>
        <Col md={3}>
          <Link className="analytics flex-column align-items-center justify-content-center pay mb-3">
            <img
              src="/images/analitics/analitics-pay.svg"
              height={65}
              className="mb-4"
            />
            <h4 className="mb-1">369 541</h4>
            <span>Комиссия за месяц</span>
          </Link>
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <Card body>
            <h5 className="mb-3 fw-6 h6">Статистика по задачам</h5>
            <div className="d-flex text-muted fs-09">Пока ничего нет</div>
          </Card>
        </Col>
        <Col md={4}>
          <Card body>
            <h5 className="mb-3 fw-6 h6">Топ админов</h5>
            <div className="d-flex text-muted fs-09">Пока ничего нет</div>
          </Card>
        </Col>
        <Col md={4}>
          <Card body>
            <h5 className="mb-3 fw-6 h6">Топ пользователей</h5>
            <div className="d-flex text-muted fs-09">Пока ничего нет</div>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Admin;
