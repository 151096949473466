import React, {
  useCallback,
  useState,
  useEffect,
  useLayoutEffect,
} from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { useForm, useWatch } from "react-hook-form";
import { IoChevronBackOutline } from "react-icons/io5";
import { NotificationManager } from "react-notifications";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import ImageCropper from "../../components/Cropper";
import DragDropFile from "../../components/DragDropFile";
import Meta from "../../components/Meta";
import Input from "../../components/UI/Input";
import Textarea from "../../components/UI/Textarea";
import { createSale } from "../../services/sale";
import Select from "../../components/UI/Select";

const SaleCreate = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const {
    control,
    register,
    formState: { errors, isValid },
    handleSubmit,
    setValue,
    reset,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onSubmit",
  });

  const data = useWatch({ control });

  const [editImageSale, setEditImageSale] = useState({
    show: false,
    data: [],
  });
  const [editImageMiniSale, setEditImageMiniSale] = useState({
    show: false,
    data: [],
  });
  const [editImageAdSale, setEditImageAdSale] = useState({
    show: false,
    data: [],
  });

  useEffect(() => {
    searchParams.get("type") &&
      reset({ type: Number(searchParams.get("type")) });
  }, []);

  const onSubmit = useCallback((data) => {
    createSale(data)
      .then(() => {
        NotificationManager.success("Баннер успешно создана");
        navigate(-1);
      })
      .catch((error) => {
        NotificationManager.error(
          error?.response?.data?.error ?? "Ошибка при сохранении"
        );
      });
  }, []);

  return (
    <>
      <Meta title="Создать баннер" />
      <div>
        <Link
          to="/catalog/sales"
          className="d-inline-flex align-items-center mb-3 fs-09 text-muted"
        >
          <IoChevronBackOutline className="me-2" size={18} /> Назад к списку
        </Link>
      </div>
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h3>Создать баннер</h3>
        <Button disabled={!isValid} onClick={handleSubmit(onSubmit)}>
          Сохранить
        </Button>
      </div>
      <Card>
        <Card.Body>
          <div className="mb-3">
            <Select
              label="Тип баннера"
              data={[
                { title: "Основной баннер", value: 0 },
                { title: "Рекламнный баннер", value: 1 },
              ]}
              value={data.type ?? 0}
              onClick={(e) => setValue("type", e.value)}
            />
          </div>
          {data?.type === 1 ? (
            <Row>
              <Col md={12}>
                <div className="mb-3">
                  <Select
                    label="Расположение"
                    data={[{ title: "Главная", value: 0 }]}
                    value={data.location ?? 0}
                    onClick={(e) => setValue("location", e.value)}
                  />
                </div>
              </Col>
              <Col md={12}>
                <div className="mb-3">
                  <Input
                    label="Заголовок"
                    name="title"
                    errors={errors}
                    register={register}
                    validation={{
                      required: "Обязательное поле",
                    }}
                  />
                </div>
              </Col>
              <Col md={12}>
                <div className="mb-3">
                  <Textarea
                    rows={6}
                    label="Текст"
                    name="desc"
                    errors={errors}
                    register={register}
                  />
                </div>
              </Col>
              <Col md={12}>
                <div className="mb-3">
                  <Input
                    label="Текст для кнопки"
                    name="btn"
                    placeholder="Перейти"
                    errors={errors}
                    register={register}
                  />
                </div>
              </Col>
              <Col md={12}>
                <DragDropFile
                  file={data.media}
                  onChange={(e) =>
                    setEditImageAdSale((info) => ({
                      show: !info.show,
                      data: e,
                    }))
                  }
                />
                <ImageCropper
                  file={editImageAdSale.data[0]}
                  show={editImageAdSale.show}
                  aspect={1116 / 143}
                  setShow={(e) =>
                    setEditImageAdSale((info) => ({ ...info, show: e }))
                  }
                  onComplete={(e) => {
                    e && setValue("file", e.file);
                    e && setValue("media", e.blob);
                  }}
                />
              </Col>
            </Row>
          ) : (
            <Row>
              <Col md={6}>
                <div className="mb-3">
                  <Select
                    label="Расположение"
                    data={[{ title: "Главная", value: 0 }]}
                    value={data.location ?? 0}
                    onClick={(e) => setValue("location", e.value)}
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <Select
                    label="Название игры"
                    data={[{ title: "Lineage 2", value: 0 }]}
                    value={data.game ?? 0}
                    onClick={(e) => setValue("game", e.value)}
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <Input
                    defaultValue={0}
                    label="Порядок"
                    name="priority"
                    errors={errors}
                    register={register}
                  />
                </div>
              </Col>
              <Col md={6}>
                <Form.Check className="mt-2 mb-3">
                  <Form.Check.Input
                    type="checkbox"
                    name="status"
                    id="status"
                    defaultChecked={true}
                    {...register("status")}
                  />
                  <Form.Check.Label htmlFor="status" className="ms-2">
                    Показать\Скрыть баннер
                  </Form.Check.Label>
                </Form.Check>
              </Col>
              <Col md={3}>
                <DragDropFile
                  title="Выбрать миниатюру"
                  file={data.mediaMini}
                  onChange={(e) =>
                    setEditImageMiniSale((info) => ({
                      show: !info.show,
                      data: e,
                    }))
                  }
                />
                <ImageCropper
                  file={editImageMiniSale.data[0]}
                  show={editImageMiniSale.show}
                  aspect={23 / 43}
                  setShow={(e) =>
                    setEditImageMiniSale((info) => ({ ...info, show: e }))
                  }
                  onComplete={(e) => {
                    e && setValue("fileMini", e.file);
                    e && setValue("mediaMini", e.blob);
                  }}
                />
              </Col>
              <Col md={9}>
                <DragDropFile
                  title="Выбрать основное изображение"
                  file={data.media}
                  onChange={(e) =>
                    setEditImageSale((info) => ({
                      show: !info.show,
                      data: e,
                    }))
                  }
                />
                <ImageCropper
                  file={editImageSale.data[0]}
                  show={editImageSale.show}
                  aspect={265 / 149}
                  setShow={(e) =>
                    setEditImageSale((info) => ({ ...info, show: e }))
                  }
                  onComplete={(e) => {
                    e && setValue("file", e.file);
                    e && setValue("media", e.blob);
                  }}
                />
              </Col>
            </Row>
          )}
        </Card.Body>
      </Card>
    </>
  );
};

export default SaleCreate;
