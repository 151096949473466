import moment from "moment";
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import {
  IoCreateOutline,
  IoSearchOutline,
  IoTrashOutline,
} from "react-icons/io5";
import { NotificationManager } from "react-notifications";
import { useSelector } from "react-redux";
import { Link, useSearchParams } from "react-router-dom";
import DataTable from "../../components/DataTable";
import Meta from "../../components/Meta";
import Button from "../../components/UI/Button";
import Info from "../../components/UI/Info";
import Input from "../../components/UI/Input";
import Loader from "../../components/UI/Loader";
import Select from "../../components/UI/Select";
import CustomModal from "../../components/utils/CustomModal";
import { getImageURL } from "../../helpers/image";
import { customPrice } from "../../helpers/product";
import { getCategories } from "../../services/category";
import { deleteProduct, getProducts } from "../../services/product";

const Products = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [products, setProducts] = useState({
    loading: true,
    items: [],
  });
  const [categories, setCategories] = useState({
    loading: true,
    items: [],
  });

  const [selected, setSelected] = useState([]);
  const [modalDelete, setModalDelete] = useState({
    show: false,
    id: false,
  });

  const productColumns = [
    {
      name: "Фото",
      width: "60px",
      selector: "media",
      cell: (row) => (
        <img
          src={getImageURL(row?.medias)}
          width={30}
          height={30}
          className="mini-img"
        />
      ),
    },
    {
      name: "Название",
      selector: "title",
    },
    {
      name: "Категория",
      selector: "category",
      cell: (row) => row?.category?.title ?? null,
    },
    {
      name: "Стоимость",
      selector: "price",
      cell: (row) =>
        row?.modifiers?.length > 0
          ? "от " +
            customPrice(
              Math.min.apply(
                null,
                row.modifiers.map((e) => e.price)
              )
            )
          : customPrice(row.price),
      size: 2,
    },
    {
      name: "Дата обновления",
      cell: (row) => moment(row.updatedAt).fromNow(),
      size: 2,
    },
    {
      width: "80px",
      selector: "action",
      align: "right",
      cell: (row) => (
        <div className="d-flex align-items-center">
          <Link
            disabled={selected.length > 0}
            to={"/catalog/product/" + row.id}
            className="me-4"
          >
            <IoCreateOutline size={22} />
          </Link>
          <a
            disabled={selected.length > 0}
            onClick={() =>
              setModalDelete({ show: !modalDelete.show, id: row.id })
            }
          >
            <IoTrashOutline size={20} className="text-danger" />
          </a>
        </div>
      ),
    },
  ];

  const getData = useCallback(async () => {
    getProducts(searchParams)
      .then(
        (res) =>
          res &&
          setProducts((prev) => ({
            ...prev,
            loading: false,
            ...res,
          }))
      )
      .finally(() => setProducts((prev) => ({ ...prev, loading: false })));
  }, [searchParams]);

  const onDelete = useCallback((id) => {
    deleteProduct(id)
      .then(() => {
        getData();
        NotificationManager.success("Товар успешно удален");
        setModalDelete({ show: false, id: false });
      })
      .catch(() => NotificationManager.error("Ошибка при запросе"));
  }, []);

  const onDeleteSelected = useCallback(() => {
    deleteProduct(selected.map((e) => e.item.id))
      .then(() => {
        setSelected([]);
        getData();
        NotificationManager.success("Выбранные товары успешно удалены");
        setModalDelete({ show: false, id: false });
      })
      .catch(() => NotificationManager.error("Ошибка при запросе"));
  }, [selected]);

  const onSearch = useCallback(() => {
    getData();
  }, [searchParams.get("text"), searchParams.get("categoryId")]);

  useLayoutEffect(() => {
    getCategories(1, 200)
      .then((res) => {
        if (res?.items?.length > 0) {
          let array = res.items.map((e) => ({ title: e.title, value: e.id }));
          console.log(array);
          setCategories((prev) => ({
            ...prev,
            loading: false,
            items: array,
          }));
        }
      })
      .finally(() => setCategories((prev) => ({ ...prev, loading: false })));
  }, []);

  useLayoutEffect(() => {
    getData();
  }, [searchParams.get("page")]);

  const Header = () => {
    let isView = !!selected?.length > 0;

    const [offset, setOffset] = useState(window.scrollY);

    const onScroll = () => {
      setOffset(window.scrollY);
    };

    useEffect(() => {
      window.removeEventListener("scroll", onScroll);
      window.addEventListener("scroll", onScroll, { passive: true });
      return () => window.removeEventListener("scroll", onScroll);
    }, []);

    return (
      <div
        className={
          "card-header-box d-flex align-items-center justify-content-between " +
          (offset > 30 ? "bg-white " : "")
        }
      >
        <div className="d-flex align-items-center">
          <h5 className="fw-7 me-3">
            {isView ? `Выбрано ${selected.length}` : "Товары"}
          </h5>
          <Link to="/catalog/products/create" className="btn-primary-outline">
            Добавить товар
          </Link>
        </div>
        <div className="d-flex align-items-center">
          {/* <div className="me-3">
            <Input
              name="text"
              placeholder="Поиск..."
              onChange={(e) => {
                searchParams.set("text", e);
                setSearchParams(searchParams);
              }}
              defaultValue={searchParams.get("text")}
              rightIcon={() => <IoSearchOutline size={20} />}
              rightIconClick={() => onSearch()}
              onKeyDown={(e) => e === "Enter" && onSearch()}
            />
          </div> */}
          <div>
            <Select
              className="me-3"
              label="Категория"
              data={categories.items}
              value={searchParams.get("categoryId")}
              onClick={(e) => {
                searchParams.set("categoryId", e.value);
                setSearchParams(searchParams);
                onSearch();
              }}
            />
          </div>
          <div>
            <Button
              disabled={!isView}
              className="btn-light"
              onClick={() => setModalDelete({ show: !modalDelete.show })}
            >
              <IoTrashOutline size={18} />
            </Button>
          </div>
        </div>
      </div>
    );
  };

  if (products.loading) {
    return <Loader full />;
  }

  if (!products.items || products.items.length === 0) {
    return (
      <>
        <Meta title="Товары" />
        <Info>
          <svg
            className="mb-3"
            width="60"
            height="60"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.32"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12 22.5C17.799 22.5 22.5 17.799 22.5 12C22.5 6.20101 17.799 1.5 12 1.5C6.20101 1.5 1.5 6.20101 1.5 12C1.5 17.799 6.20101 22.5 12 22.5ZM12 18.3C15.4794 18.3 18.3 15.4794 18.3 12C18.3 8.52061 15.4794 5.7 12 5.7C8.52061 5.7 5.7 8.52061 5.7 12C5.7 15.4794 8.52061 18.3 12 18.3Z"
              fill="#999"
            />
            <path
              d="M18.6028 3.01136C19.2179 2.39628 20.2151 2.39628 20.8302 3.01136C21.4453 3.62643 21.4453 4.62367 20.8302 5.23874L5.2385 20.8304C4.62342 21.4455 3.62619 21.4455 3.01111 20.8304C2.39604 20.2154 2.39604 19.2181 3.01111 18.6031L18.6028 3.01136Z"
              fill="#999"
            />
          </svg>
          <h3>Пока товаров нет</h3>
          <Link className="mt-3 btn btn-primary" to="/catalog/products/create">
            Добавить товар
          </Link>
        </Info>
      </>
    );
  }

  return (
    <>
      <Meta title="Товары" />
      <DataTable
        columns={productColumns}
        onChange={(items) => setSelected(items)}
        data={products.items}
        header={<Header />}
        classNameHeader="p-0 sticky"
        selectable
        statusBackground="product"
        pagination={products.pagination}
      />
      <CustomModal
        title={
          selected.length > 0
            ? `Удаление ${selected.length} элементов`
            : "Удаление элемента"
        }
        show={modalDelete.show}
        setShow={(e) => setModalDelete({ show: e, id: false })}
        footer={
          <>
            <Button
              className="me-3"
              onClick={() =>
                setModalDelete({ show: !modalDelete.show, id: false })
              }
            >
              Отмена
            </Button>
            <Button
              className="btn-danger"
              onClick={() =>
                selected.length > 0
                  ? onDeleteSelected()
                  : modalDelete.id && onDelete(modalDelete.id)
              }
            >
              Удалить
            </Button>
          </>
        }
      >
        Вы точно хотите удалить товар(-ы)?
      </CustomModal>
    </>
  );
};

export default Products;
