import React from "react";
import { Route, Routes } from "react-router-dom";

import NotFound from "../pages/NotFound";

import Orders from "../pages/order";
import OrderEdit from "../pages/order/Edit";

import Notifications from "../pages/notification";
import NotificationCreate from "../pages/notification/Create";

import Categories from "../pages/category";
import CategoryCreate from "../pages/category/Create";
import CategoryEdit from "../pages/category/Edit";

import Products from "../pages/product";
import ProductCreate from "../pages/product/Create";
import ProductEdit from "../pages/product/Edit";

import Users from "../pages/user";
import UserEdit from "../pages/user/Edit";

import Sales from "../pages/sale";
import SaleCreate from "../pages/sale/Create";
import SaleEdit from "../pages/sale/Edit";

import Documents from "../pages/document";
import DocumentCreate from "../pages/document/Create";
import DocumentEdit from "../pages/document/Edit";

import Members from "../pages/member";
import MemberEdit from "../pages/member/Edit";

import CatalogRoute from "../layouts/CatalogRoute";
import Account from "../pages/account";

import Dialogs from "../pages/dialog";
import Payment from "../pages/invoice";
import Invoices from "../pages/invoice/Invoices";
import Invoice from "../pages/invoice/View";
import MemberCreate from "../pages/member/Create";

const AdminRouter = () => {
  return (
    <Routes>
      <Route path="/">
        <Route path="account" element={<Account />} />

        <Route path="orders" element={<Orders />} />
        <Route path="order/:orderId" element={<OrderEdit />} />

        <Route path="dialogs" element={<Dialogs />} />
        {/* <Route path="dialog/:dialogId" element={<Chat />} /> */}

        <Route path="payment" element={<Payment />} />
        <Route path="invoices" element={<Invoices />} />
        <Route path="invoice/:invoiceId" element={<Invoice />} />

        <Route path="notifications" element={<Notifications />} />
        <Route path="notifications/create" element={<NotificationCreate />} />

        <Route path="users" element={<Users />} />
        <Route path="user/:userId" element={<UserEdit />} />

        <Route path="members" element={<Members />} />
        <Route path="members/create" element={<MemberCreate />} />
        <Route path="member/:memberId" element={<MemberEdit />} />

        <Route path="/catalog" element={<CatalogRoute />}>
          <Route path="products" element={<Products />} />
          <Route path="product/:productId" element={<ProductEdit />} />
          <Route path="products/create" element={<ProductCreate />} />

          <Route path="categories" element={<Categories />} />
          <Route path="category/:categoryId" element={<CategoryEdit />} />
          <Route path="categories/create" element={<CategoryCreate />} />

          <Route path="sales" element={<Sales />} />
          <Route path="sale/:saleId" element={<SaleEdit />} />
          <Route path="sales/create" element={<SaleCreate />} />

          <Route path="documents" element={<Documents />} />
          <Route path="document/:documentId" element={<DocumentEdit />} />
          <Route path="documents/create" element={<DocumentCreate />} />
        </Route>
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default AdminRouter;
