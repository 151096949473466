import moment from "moment/moment";
import React, { useCallback, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useForm, useWatch } from "react-hook-form";
import { IoKeyOutline } from "react-icons/io5";
import { NotificationManager } from "react-notifications";
import { useSelector } from "react-redux";
import Meta from "../../components/Meta";
import Button from "../../components/UI/Button";
import Info from "../../components/UI/Info";
import Input from "../../components/UI/Input";
import Select from "../../components/UI/Select";
import { convertColor } from "../../helpers/convertColor";
import { isRole, roles } from "../../helpers/member";
import { editMember } from "../../services/member";

const MemberCreate = () => {
  const user = useSelector((state) => state.auth.user);
  const [btnLoading, setBtnLoading] = useState(false);

  const {
    control,
    register,
    formState: { errors, isValid },
    handleSubmit,
    setValue,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onSubmit",
  });
  const form = useWatch({ control });

  const role = isRole(user);

  const onSubmit = useCallback((data) => {
    setBtnLoading(true);
    editMember(data)
      .then(() => NotificationManager.success("Данные успешно обновлены"))
      .catch((err) => err && NotificationManager.error("Ошибка при сохранении"))
      .finally(() => setBtnLoading(false));
  }, []);

  return (
    <>
      <Meta title="Создать сотрудника" />
      <h3 className="mb-4">Создать сотрудника</h3>
      <Row>
        <Col md={4}>
          <Card body>
            <div className="mb-4 d-flex flex-column align-items-center position-relative">
              <img src="/images/avatar-full.png" height={110} />
            </div>
            <div className="mb-4">
              <Input
                label="Email"
                name="email"
                errors={errors}
                defaultValue={form.email}
                register={register}
              />
            </div>
            <div className="mb-4">
              <Input
                label="Номер телефона"
                name="phone"
                mask="+7(999)999-99-99"
                errors={errors}
                defaultValue={form.phone}
                register={register}
                validation={{ required: "Обязательное поле" }}
              />
            </div>
            <Button
              className="btn btn-primary w-100"
              isValid={isValid}
              isLoading={btnLoading}
              disabled={btnLoading}
              onClick={handleSubmit(onSubmit)}
            >
              Сохранить изменения
            </Button>
          </Card>
        </Col>
        <Col md={8}>
          <Card body className="mb-4">
            {/* <h5 className="mb-4 fw-6 h6">Паспортные данные</h5>
            <Row>
              <Col md={4}>
                <div className="mb-4">
                  <Input
                    label="Фамилия"
                    name="lastName"
                    errors={errors}
                    register={register}
                    validation={{
                      maxLength: {
                        value: 50,
                        message: "Максимально 50 символов",
                      },
                    }}
                  />
                </div>
              </Col>
              <Col md={4}>
                <div className="mb-4">
                  <Input
                    label="Имя"
                    name="firstName"
                    errors={errors}
                    register={register}
                    validation={{
                      required: "Введите имя",
                      maxLength: {
                        value: 20,
                        message: "Максимально 20 символов",
                      },
                    }}
                  />
                </div>
              </Col>
              <Col md={4}>
                <div className="mb-4">
                  <Input
                    label="Отчество"
                    name="patronymic"
                    errors={errors}
                    register={register}
                    validation={{
                      maxLength: {
                        value: 50,
                        message: "Максимально 50 символов",
                      },
                    }}
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-4">
                  <Input
                    type="date"
                    label="День рождения"
                    name="brithday"
                    errors={errors}
                    register={register}
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-4">
                  <Input
                    type="text"
                    label="Номер паспорта"
                    name="passport.number"
                    errors={errors}
                    register={register}
                    validation={{
                      maxLength: {
                        value: 20,
                        message: "Максимально 20 символов",
                      },
                    }}
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-4">
                  <Input
                    type="date"
                    label="Дата выдачи"
                    name="passport.outputDate"
                    errors={errors}
                    register={register}
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-4">
                  <Input
                    type="text"
                    label="Код подразделения"
                    name="passport.code"
                    errors={errors}
                    register={register}
                    validation={{
                      maxLength: {
                        value: 20,
                        message: "Максимально 20 символов",
                      },
                    }}
                  />
                </div>
              </Col>
              <Col md={12}>
                <div className="mb-4">
                  <Textarea
                    label="Кем выдан"
                    name="passport.outputBy"
                    rows={1}
                    errors={errors}
                    register={register}
                    validation={{
                      maxLength: {
                        value: 500,
                        message: "Максимально 500 символов",
                      },
                    }}
                  />
                </div>
              </Col>
              <Col md={12}>
                <div className="mb-4">
                  <Textarea
                    label="Регистрация"
                    name="passport.addressRegistration"
                    rows={1}
                    errors={errors}
                    register={register}
                    validation={{
                      maxLength: {
                        value: 500,
                        message: "Максимально 500 символов",
                      },
                    }}
                  />
                </div>
              </Col>
            </Row> */}
            <h5 className="mb-4 fw-6 h6">Настройки</h5>
            <Row>
              <Col md={6}>
                <Select
                  className="mb-4"
                  label="Отдел"
                  onClick={(e) => setValue("groupRole", e.value)}
                  value={form.groupRole}
                  data={roles}
                />
              </Col>
              {form.groupRole && (
                <Col md={6}>
                  <Select
                    className="mb-4"
                    label="Должность"
                    onClick={(e) => setValue("role", e.value)}
                    value={form.role}
                    data={roles.find((e) => e.value === form.groupRole)?.data}
                  />
                </Col>
              )}
            </Row>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default MemberCreate;
