import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
  active: false,
};

const brandSlice = createSlice({
  name: "brand",
  initialState,
  reducers: {
    setBrands: (state, action) => {
      state.data = action.payload;
      state.active = action.payload.find((e) => e.main);
    },
  },
});

export const { setBrands } = brandSlice.actions;

export default brandSlice.reducer;
