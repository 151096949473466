import { $api, $authApi } from ".";
import { apiRoutes } from "../config/api";

const getAffiliates = async (data) => {
  const response = await $authApi.get(apiRoutes.AFFILIATE, {
    params: data,
  });
  return response?.data;
};
const getAffiliate = async (id) => {
  const response = await $authApi.get(apiRoutes.AFFILIATE_ONE, {
    params: {
      id,
    },
  });
  return response?.data;
};
const editAffiliate = async (affiliate) => {
  const response = await $authApi.put(apiRoutes.AFFILIATE, affiliate);
  return response?.data;
};
const mainAffiliate = async (id) => {
  const response = await $authApi.put(apiRoutes.AFFILIATE_MAIN, { id });
  return response?.data;
};
const deleteAffiliate = async (id) => {
  const response = await $authApi.delete(apiRoutes.AFFILIATE, {
    data: { id },
  });
  return response?.data;
};
const createAffiliate = async (affiliate) => {
  const response = await $authApi.post(apiRoutes.AFFILIATE_CREATE, affiliate);
  return response?.data;
};
export {
  getAffiliates,
  getAffiliate,
  editAffiliate,
  deleteAffiliate,
  createAffiliate,
  mainAffiliate,
};
