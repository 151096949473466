import { useSelector } from "react-redux";
import Message from "./Message";
import { IoSend } from "react-icons/io5";
import { memo, useCallback, useState } from "react";
import Input from "../UI/Input";
import { HiPaperClip } from "react-icons/hi2";

const Chat = memo(
  ({ data, className, emptyText = "Нет сообщений", onSubmit }) => {
    const auth = useSelector((state) => state.auth);
    const [text, setText] = useState("");
    const onClick = useCallback(() => {
      if (text.length > 0 && onSubmit) {
        onSubmit(text);
        setText("");
      }
    }, [text]);

    return (
      <>
        <div
          className={
            "chat" +
            (!data || data?.length === 0
              ? " d-flex justify-content-center align-items-center"
              : "")
          }
        >
          {data.length > 0 ? (
            data.map((e) => (
              <Message
                my={e.my}
                name={e.name ?? "Вы"}
                time={e.createdAt}
                text={e.text}
                view={e.view}
              />
            ))
          ) : (
            <div className="w-100 py-5 text-center text-muted fs-09 d-flex flex-column align-items-center justify-content-center">
              {emptyText}
            </div>
          )}
        </div>
        <form className="d-flex align-items-center position-relative pt-2">
          <div className="me-3">
            <img src="/images/avatar-full.png" width={40} height={40} />
          </div>
          <div className="w-100">
            <Input
              rightIcon={() => <HiPaperClip size={22} />}
              value={text}
              placeholder="Введите сообщение"
              onChange={(e) => setText(e)}
            />
          </div>
          <a onClick={() => onClick()} className="ms-4">
            <IoSend
              size={22}
              className={text.length > 0 ? "text-success" : "text-muted"}
            />
          </a>
        </form>
      </>
    );
  }
);

export default Chat;
