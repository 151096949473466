import React from "react";
import { Badge } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import Footer from "./Footer";

const AdminMenu = () => {
  return (
    <div className="w-100">
      <Link
        to="/"
        className="fs-12 fw-7 p-2 py-3 d-flex align-items-center logo"
      >
        <img src="/logo.svg" height={40} />
        {/* <span className="ms-3 fw-8">RUSH2PLAY</span> */}
      </Link>
      <nav className="account-menu position-sticky top-1 mb-4">
        <ul>
          <li>
            <NavLink to="/" end>
              <span className="d-flex flex-row align-items-center">
                <svg
                  className="icon"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M20.9603 6.0506C20.9106 7.03855 19.9709 7.40975 19.242 6.741C18.9106 6.4369 18.5138 6.06255 18.0418 5.60285C16.9756 6.5731 15.322 8.08585 13.3506 9.91645C12.8329 10.3972 11.992 10.3435 11.5397 9.8007L11.5394 9.8003C10.6518 8.73835 9.75755 7.6807 8.8322 6.6514C7.7509 7.4453 5.81 8.95825 3.88389 10.8844C3.39573 11.3725 2.60427 11.3725 2.11612 10.8844C1.62796 10.3962 1.62796 9.60475 2.11612 9.1166C4.00573 7.227 6.079 5.4545 8.30255 3.96314L8.306 3.96082C8.30685 3.96027 8.3066 3.96042 8.3066 3.96042C8.8024 3.6299 9.46255 3.69527 9.8839 4.1166C10.8519 5.08455 11.7265 6.14925 12.6118 7.19265C14.1169 5.80065 15.3848 4.64087 16.274 3.8314C15.8705 3.41506 15.5362 3.06006 15.26 2.75898C14.591 2.02997 14.9624 1.08998 15.9506 1.04025C17.2115 0.976795 18.5055 0.939456 19.7511 1.17232C20.3119 1.27718 20.7233 1.68863 20.8281 2.24948C21.061 3.49521 21.0238 4.78949 20.9603 6.0506Z" />
                  <g opacity="0.32">
                    <path d="M2.60946 22.9843C1.77292 22.9631 1.13928 22.4599 1.07089 21.6259C1.02993 21.1264 1 20.4398 1 19.5C1 18.5602 1.02993 17.8736 1.07089 17.3741C1.13928 16.5401 1.77292 16.0369 2.60946 16.0157C2.97545 16.0064 3.43306 16 4 16C4.56694 16 5.02455 16.0064 5.39055 16.0157C6.2271 16.0369 6.8607 16.5401 6.9291 17.3741C6.97005 17.8736 7 18.5602 7 19.5C7 20.4398 6.97005 21.1264 6.9291 21.6259C6.8607 22.4599 6.2271 22.9631 5.39055 22.9843C5.02455 22.9936 4.56694 23 4 23C3.43306 23 2.97545 22.9936 2.60946 22.9843Z" />
                    <path d="M18.8455 22.9792C17.8709 22.9408 17.1875 22.2557 17.1243 21.2823C17.0588 20.2731 17 18.6337 17 16C17 13.3663 17.0588 11.727 17.1243 10.7177C17.1875 9.74435 17.8709 9.0592 18.8455 9.02075C19.1671 9.00805 19.549 9 20 9C20.451 9 20.8329 9.00805 21.1545 9.02075C22.1291 9.0592 22.8125 9.74435 22.8757 10.7177C22.9412 11.727 23 13.3663 23 16C23 18.6337 22.9412 20.2731 22.8757 21.2823C22.8125 22.2557 22.1291 22.9408 21.1545 22.9792C20.8329 22.992 20.451 23 20 23C19.549 23 19.1671 22.992 18.8455 22.9792Z" />
                    <path d="M10.7766 22.9832C9.8427 22.9548 9.162 22.3419 9.0949 21.41C9.0422 20.6775 9 19.5936 9 18C9 16.4064 9.0422 15.3225 9.0949 14.59C9.162 13.6581 9.8427 13.0453 10.7766 13.0169C11.1122 13.0067 11.5163 13 12 13C12.4837 13 12.8878 13.0067 13.2234 13.0169C14.1573 13.0453 14.838 13.6581 14.9051 14.59C14.9578 15.3225 15 16.4064 15 18C15 19.5936 14.9578 20.6775 14.9051 21.41C14.838 22.3419 14.1573 22.9548 13.2234 22.9832C12.8878 22.9934 12.4837 23 12 23C11.5163 23 11.1122 22.9934 10.7766 22.9832Z" />
                  </g>
                </svg>
                <span>Аналитика</span>
              </span>
            </NavLink>
          </li>
          <li>
            <NavLink to="users">
              <span className="d-flex flex-row align-items-center">
                <svg
                  className="icon"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.32"
                    d="M2.28099 19.6575C2.36966 20.5161 2.93261 21.1957 3.77688 21.3755C5.1095 21.6592 7.6216 22 12 22C16.3784 22 18.8905 21.6592 20.2232 21.3755C21.0674 21.1957 21.6304 20.5161 21.719 19.6575C21.8505 18.3844 22 16.0469 22 12C22 7.95305 21.8505 5.6156 21.719 4.34251C21.6304 3.48389 21.0674 2.80424 20.2231 2.62451C18.8905 2.34081 16.3784 2 12 2C7.6216 2 5.1095 2.34081 3.77688 2.62451C2.93261 2.80424 2.36966 3.48389 2.28099 4.34251C2.14952 5.6156 2 7.95305 2 12C2 16.0469 2.14952 18.3844 2.28099 19.6575Z"
                  />
                  <path d="M13.9382 13.8559C15.263 13.1583 16.1663 11.768 16.1663 10.1666C16.1663 7.8655 14.3008 6 11.9996 6C9.69841 6 7.83291 7.8655 7.83291 10.1666C7.83291 11.768 8.73626 13.1584 10.0612 13.856C8.28691 14.5319 6.93216 16.1092 6.51251 18.0529C6.45446 18.3219 6.60246 18.5981 6.87341 18.6471C7.84581 18.8231 9.45616 19 12.0006 19C14.545 19 16.1554 18.8231 17.1278 18.6471C17.3977 18.5983 17.5454 18.3231 17.4876 18.0551C17.0685 16.1103 15.7133 14.5321 13.9382 13.8559Z" />
                </svg>
                <span>Пользователи</span>
              </span>
            </NavLink>
          </li>
          <li>
            <NavLink to="tasks" disabled>
              <span className="d-flex flex-row align-items-center">
                <svg
                  className="icon"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="icons/navbar/ic_kanban">
                    <path
                      id="shape"
                      opacity="0.32"
                      d="M22 10.5C22 13.2058 21.9268 15.3259 21.8596 16.7018C21.8139 17.6385 20.9066 18.311 19.9771 18.1857C18.4531 17.9803 15.8791 17.75 12 17.75C8.1209 17.75 5.54695 17.9803 4.02285 18.1857C3.09341 18.311 2.18614 17.6385 2.14039 16.7018C2.07321 15.3259 2 13.2058 2 10.5C2 7.79427 2.07321 5.67417 2.14039 4.29829C2.18614 3.36156 3.09341 2.68909 4.02285 2.81433C5.54695 3.0197 8.1209 3.25002 12 3.25002C15.8791 3.25002 18.4531 3.0197 19.9771 2.81433C20.9066 2.68909 21.8139 3.36156 21.8596 4.29829C21.9268 5.67417 22 7.79427 22 10.5Z"
                    />
                    <g id="shape_2">
                      <path d="M5.59096 18.0096C5.28385 18.7423 4.94579 19.6105 4.65668 20.5C4.5685 20.7713 4.73269 21.0577 5.01662 21.0852C5.48092 21.1304 5.97612 21.1092 6.35562 21.0766C6.76872 21.0412 7.13172 20.805 7.35657 20.4566C7.94702 19.542 8.51881 18.5894 8.97868 17.8008C7.63073 17.8483 6.50815 17.9246 5.59096 18.0096Z" />
                      <path d="M15.022 17.8008C15.4818 18.5894 16.0536 19.542 16.6441 20.4566C16.8689 20.805 17.2319 21.0412 17.645 21.0766C18.0245 21.1092 18.5197 21.1304 18.984 21.0852C19.268 21.0577 19.4321 20.7713 19.344 20.5C19.0549 19.6105 18.7168 18.7424 18.4097 18.0097C17.4925 17.9246 16.37 17.8483 15.022 17.8008Z" />
                    </g>
                  </g>
                </svg>

                <span>Задачи</span>
              </span>
              <Badge className="ms-2" bg="danger">
                8
              </Badge>
            </NavLink>
          </li>
          <li className="mb-4">
            <NavLink to="members" disabled>
              <span className="d-flex flex-row align-items-center">
                <svg
                  className="icon"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    id="Subtract"
                    opacity="0.32"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M3.77688 21.3755C2.93261 21.1957 2.36966 20.5161 2.28099 19.6575C2.14952 18.3844 2 16.0469 2 12C2 7.95305 2.14952 5.6156 2.28099 4.34251C2.36966 3.48389 2.93261 2.80424 3.77688 2.62451C5.1095 2.34081 7.6216 2 12 2C16.3784 2 18.8905 2.34081 20.2231 2.62451C21.0674 2.80424 21.6304 3.48389 21.719 4.34251C21.8505 5.6156 22 7.95305 22 12C22 16.0469 21.8505 18.3844 21.719 19.6575C21.6304 20.5161 21.0674 21.1957 20.2232 21.3755C18.8905 21.6592 16.3784 22 12 22C7.6216 22 5.1095 21.6592 3.77688 21.3755ZM18.1663 8.16665C18.1663 9.76795 17.263 11.1582 15.9382 11.8559C17.7133 12.5321 19.0685 14.1103 19.4876 16.0551C19.5454 16.3231 19.3977 16.5983 19.1278 16.6471C18.1554 16.8231 16.545 17 14.0006 17C11.4562 17 9.84581 16.8231 8.87341 16.6471C8.60246 16.5981 8.45446 16.3219 8.51251 16.0529C8.93216 14.1092 10.2869 12.5319 12.0612 11.856C10.7363 11.1584 9.83291 9.768 9.83291 8.16665C9.83291 5.8655 11.6984 4 13.9996 4C16.3008 4 18.1663 5.8655 18.1663 8.16665Z"
                    fill="#637381"
                  />
                  <path
                    id="shape"
                    d="M12.4382 14.8559C13.763 14.1583 14.6663 12.768 14.6663 11.1666C14.6663 8.8655 12.8008 7 10.4996 7C8.19841 7 6.33291 8.8655 6.33291 11.1666C6.33291 12.768 7.23626 14.1584 8.56116 14.856C6.78691 15.5319 5.43216 17.1092 5.01251 19.0529C4.95446 19.3219 5.10246 19.5981 5.37341 19.6471C6.34581 19.8231 7.95616 20 10.5006 20C13.045 20 14.6554 19.8231 15.6278 19.6471C15.8977 19.5983 16.0454 19.3231 15.9876 19.0551C15.5685 17.1103 14.2133 15.5321 12.4382 14.8559Z"
                    fill="#637381"
                  />
                </svg>

                <span>Команда</span>
              </span>
            </NavLink>
          </li>
          <li>
            <p className="nav-label">Контент</p>
          </li>
          {/* <li>
            <NavLink to="upload/categories">
              <span className="d-flex flex-row align-items-center">
                <svg
                  className="icon"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M3.51374 21.6755C2.64245 21.49 2.06149 20.7886 1.96998 19.9025C1.8343 18.5887 1.68 16.1764 1.68 12C1.68 7.82354 1.8343 5.41129 1.96998 4.09746C2.06149 3.21136 2.64245 2.50997 3.51374 2.32448C4.889 2.03171 7.48149 1.67999 12 1.67999C16.5185 1.67999 19.111 2.03171 20.4862 2.32448C21.3576 2.50997 21.9385 3.21136 22.03 4.09746C22.1657 5.41129 22.32 7.82354 22.32 12C22.32 16.1764 22.1657 18.5887 22.03 19.9025C21.9385 20.7886 21.3576 21.49 20.4863 21.6755C19.111 21.9683 16.5185 22.32 12 22.32C7.48149 22.32 4.889 21.9683 3.51374 21.6755ZM13.0903 9.92639V5.20439C13.0895 4.95415 12.9899 4.71437 12.8132 4.53743C12.6365 4.36048 12.3971 4.26074 12.1472 4.25999H7.43203C7.18485 4.26442 6.94928 4.36587 6.77603 4.54249C6.60279 4.71912 6.50572 4.95681 6.50572 5.20439C6.50572 5.45198 6.60279 5.68966 6.77603 5.86629C6.94928 6.04292 7.18485 6.14436 7.43203 6.14879H9.88393C9.44126 6.57338 7.6716 8.33905 6.48607 9.52681C6.11757 9.896 6.11869 10.4933 6.48727 10.8624C6.85716 11.2328 7.45735 11.2325 7.82679 10.8616L11.2042 7.47095V9.92639C11.2086 10.1739 11.3099 10.4098 11.4863 10.5833C11.6627 10.7568 11.9 10.854 12.1472 10.854C12.3945 10.854 12.6318 10.7568 12.8082 10.5833C12.9845 10.4098 13.0858 10.1739 13.0903 9.92639ZM11.1983 18.7956V14.0736C11.2027 13.826 11.304 13.5901 11.4804 13.4166C11.6567 13.2431 11.8941 13.1459 12.1413 13.1459C12.3885 13.1459 12.6259 13.2431 12.8022 13.4166C12.9786 13.5901 13.0799 13.826 13.0843 14.0736V16.529L16.4617 13.1384C16.8312 12.7675 17.4314 12.7671 17.8013 13.1376C18.1698 13.5067 18.171 14.104 17.8025 14.4732C16.6169 15.6609 14.8473 17.4266 14.4046 17.8512H16.8565C17.1037 17.8556 17.3393 17.9571 17.5125 18.1337C17.6857 18.3103 17.7828 18.548 17.7828 18.7956C17.7828 19.0432 17.6857 19.2809 17.5125 19.4575C17.3393 19.6341 17.1037 19.7356 16.8565 19.74H12.1413C11.8914 19.7392 11.652 19.6395 11.4753 19.4626C11.2986 19.2856 11.199 19.0458 11.1983 18.7956Z"
                  />
                </svg>
                <span>Синхронизация</span>
              </span>
            </NavLink>
          </li> */}
          <li>
            <NavLink to="catalog/games" disabled>
              <span className="d-flex flex-row align-items-center">
                <svg
                  className="icon"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.32"
                    d="M17.2798 4.5H6.7202C5.77169 4.5 5 5.06057 5 5.75042C5 6.43943 5.77169 7 6.7202 7H17.2798C18.2283 7 19 6.43943 19 5.75042C19 5.06054 18.2283 4.5 17.2798 4.5Z"
                  />
                  <path d="M17.2798 17H6.7202C5.77169 17 5 17.5606 5 18.2504C5 18.9394 5.77169 19.5 6.7202 19.5H17.2798C18.2283 19.5 19 18.9394 19 18.2504C19 17.5606 18.2283 17 17.2798 17Z" />
                  <path d="M17.2798 10.75H6.7202C5.77169 10.75 5 11.3106 5 12.0004C5 12.6894 5.77169 13.25 6.7202 13.25H17.2798C18.2283 13.25 19 12.6894 19 12.0004C19 11.3105 18.2283 10.75 17.2798 10.75Z" />
                </svg>
                <span>Каталог игр</span>
              </span>
            </NavLink>
          </li>
          <li>
            <NavLink to="catalog/sales">
              <span className="d-flex flex-row align-items-center">
                <svg
                  className="icon"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="shape" opacity="0.32">
                    <path d="M21 8H13C12.7348 8 12.4804 7.89464 12.2929 7.70711C12.1054 7.51957 12 7.26522 12 7C12 6.73478 12.1054 6.48043 12.2929 6.29289C12.4804 6.10536 12.7348 6 13 6H21C21.2652 6 21.5196 6.10536 21.7071 6.29289C21.8946 6.48043 22 6.73478 22 7C22 7.26522 21.8946 7.51957 21.7071 7.70711C21.5196 7.89464 21.2652 8 21 8ZM21 12H13C12.7348 12 12.4804 11.8946 12.2929 11.7071C12.1054 11.5196 12 11.2652 12 11C12 10.7348 12.1054 10.4804 12.2929 10.2929C12.4804 10.1054 12.7348 10 13 10H21C21.2652 10 21.5196 10.1054 21.7071 10.2929C21.8946 10.4804 22 10.7348 22 11C22 11.2652 21.8946 11.5196 21.7071 11.7071C21.5196 11.8946 21.2652 12 21 12Z" />
                    <path d="M21 16H3C2.73478 16 2.48043 15.8946 2.29289 15.7071C2.10536 15.5196 2 15.2652 2 15C2 14.7348 2.10536 14.4804 2.29289 14.2929C2.48043 14.1054 2.73478 14 3 14H21C21.2652 14 21.5196 14.1054 21.7071 14.2929C21.8946 14.4804 22 14.7348 22 15C22 15.2652 21.8946 15.5196 21.7071 15.7071C21.5196 15.8946 21.2652 16 21 16ZM13 20H3C2.73478 20 2.48043 19.8946 2.29289 19.7071C2.10536 19.5196 2 19.2652 2 19C2 18.7348 2.10536 18.4804 2.29289 18.2929C2.48043 18.1054 2.73478 18 3 18H13C13.2652 18 13.5196 18.1054 13.7071 18.2929C13.8946 18.4804 14 18.7348 14 19C14 19.2652 13.8946 19.5196 13.7071 19.7071C13.5196 19.8946 13.2652 20 13 20Z" />
                  </g>
                  <rect id="shape_2" x="2" y="4" width="8" height="8" rx="2" />
                </svg>

                <span>Управление банерами</span>
              </span>
            </NavLink>
          </li>
          <li className="mb-4">
            <NavLink to="catalog/news" disabled>
              <span className="d-flex flex-row align-items-center">
                <svg
                  className="icon"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    id="shape"
                    opacity="0.32"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M20.9114 8.22695C19.4717 8.5891 17.7718 8.61315 16.3035 8.1646C15.6828 7.97495 15.1988 7.4914 14.9893 6.8771C14.4674 5.34677 14.3385 3.47362 14.722 2.0318C13.9279 2.01186 13.0248 2 12 2C8.51575 2 6.43945 2.13682 5.26285 2.26379C4.39116 2.35785 3.71902 2.94826 3.5558 3.80967C3.30175 5.15055 3 7.65725 3 12C3 16.3428 3.30175 18.8494 3.5558 20.1903C3.71902 21.0518 4.39116 21.6422 5.26285 21.7362C6.43945 21.8631 8.51575 22 12 22C15.4843 22 17.5606 21.8631 18.7372 21.7362C19.6089 21.6422 20.281 21.0518 20.4442 20.1903C20.6982 18.8494 21 16.3428 21 12C21 10.5445 20.9661 9.2952 20.9114 8.22695ZM8 13C7.4477 13 7 12.5523 7 12C7 11.4477 7.4477 11 8 11H12C12.5523 11 13 11.4477 13 12C13 12.5523 12.5523 13 12 13H8ZM8 17.5C7.4477 17.5 7 17.0523 7 16.5C7 15.9477 7.4477 15.5 8 15.5H15C15.5523 15.5 16 15.9477 16 16.5C16 17.0523 15.5523 17.5 15 17.5H8Z"
                  />

                  <path d="M7 16.5C7 17.0523 7.4477 17.5 8 17.5H15C15.5523 17.5 16 17.0523 16 16.5C16 15.9477 15.5523 15.5 15 15.5H8C7.4477 15.5 7 15.9477 7 16.5Z" />
                  <path d="M7 12C7 12.5523 7.4477 13 8 13H12C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11H8C7.4477 11 7 11.4477 7 12Z" />
                  <path d="M20.9114 8.22695C19.4717 8.5891 17.7718 8.61315 16.3036 8.1646C15.6828 7.97495 15.1988 7.4914 14.9893 6.8771C14.4674 5.34675 14.3384 3.47357 14.722 2.03174C14.722 2.03174 15.9461 2.49994 18.1961 4.74994C20.4461 6.99995 20.9114 8.22695 20.9114 8.22695Z" />
                </svg>
                <span>Новости</span>
              </span>
            </NavLink>
          </li>
          <li>
            <p className="nav-label">Финансы</p>
          </li>
          <li>
            <NavLink to="finance/replenishment" disabled>
              <span className="d-flex flex-row align-items-center">
                <svg
                  className="icon"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    id="shape"
                    opacity="0.32"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M3.77688 21.3755C2.93261 21.1957 2.36966 20.5161 2.28099 19.6575C2.14952 18.3844 2 16.0469 2 12C2 7.95305 2.14952 5.6156 2.28099 4.34251C2.36966 3.48389 2.93261 2.80424 3.77688 2.62451C5.1095 2.34081 7.6216 2 12 2C16.3784 2 18.8905 2.34081 20.2231 2.62451C21.0674 2.80424 21.6304 3.48389 21.719 4.34251C21.8505 5.6156 22 7.95305 22 12C22 16.0469 21.8505 18.3844 21.719 19.6575C21.6304 20.5161 21.0674 21.1957 20.2232 21.3755C18.8905 21.6592 16.3784 22 12 22C7.6216 22 5.1095 21.6592 3.77688 21.3755ZM17 8.37267V15.236C16.9936 15.5958 16.8463 15.9387 16.59 16.1909C16.3336 16.4431 15.9886 16.5844 15.6293 16.5844C15.27 16.5844 14.925 16.4431 14.6686 16.1909C14.4123 15.9387 14.265 15.5958 14.2586 15.236V11.6671L9.3496 16.5953C8.81262 17.1344 7.94025 17.135 7.40262 16.5965C6.86689 16.06 6.86528 15.1919 7.40088 14.6553C9.12404 12.9289 11.6962 10.3625 12.3396 9.74535H8.77583C8.41654 9.73891 8.07415 9.59146 7.82234 9.33474C7.57053 9.07801 7.42944 8.73254 7.42944 8.37267C7.42944 8.01281 7.57053 7.66734 7.82234 7.41061C8.07415 7.15389 8.41654 7.00644 8.77583 7H15.6293C15.9925 7.00109 16.3405 7.14606 16.5973 7.40325C16.8542 7.66044 16.9989 8.00895 17 8.37267Z"
                  />
                  <path
                    id="shape_2"
                    d="M17 15.236V8.37267C16.9989 8.00895 16.8542 7.66044 16.5973 7.40325C16.3405 7.14606 15.9925 7.00109 15.6293 7H8.77583C8.41654 7.00644 8.07415 7.15389 7.82234 7.41061C7.57053 7.66734 7.42944 8.01281 7.42944 8.37267C7.42944 8.73254 7.57053 9.07801 7.82234 9.33474C8.07415 9.59146 8.41654 9.73891 8.77583 9.74535H12.3396C11.6962 10.3625 9.12404 12.9289 7.40088 14.6553C6.86528 15.1919 6.86689 16.06 7.40262 16.5965C7.94025 17.135 8.81262 17.1344 9.3496 16.5953L14.2586 11.6671V15.236C14.265 15.5958 14.4123 15.9387 14.6686 16.1909C14.925 16.4431 15.27 16.5844 15.6293 16.5844C15.9886 16.5844 16.3336 16.4431 16.59 16.1909C16.8463 15.9387 16.9936 15.5958 17 15.236Z"
                    fill="white"
                  />
                </svg>
                <span>Пополнения</span>
              </span>
            </NavLink>
          </li>
          <li>
            <NavLink to="finance/withdrawals" disabled>
              <span className="d-flex flex-row align-items-center">
                <svg
                  className="icon"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    id="shape"
                    opacity="0.32"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M20.2251 2.62499C21.0693 2.80474 21.6323 3.48439 21.721 4.34299C21.8524 5.61609 22.002 7.95354 22.002 12.0005C22.002 16.0474 21.8524 18.3849 21.721 19.658C21.6323 20.5166 21.0693 21.1962 20.2251 21.376C18.8925 21.6597 16.3804 22.0005 12.002 22.0005C7.62355 22.0005 5.11145 21.6597 3.77885 21.376C2.93455 21.1962 2.3716 20.5166 2.28295 19.658C2.15145 18.3849 2.00195 16.0474 2.00195 12.0005C2.00195 7.95354 2.15145 5.61609 2.28295 4.34299C2.3716 3.48439 2.93455 2.80474 3.7788 2.62499C5.11145 2.34129 7.62355 2.00049 12.002 2.00049C16.3804 2.00049 18.8925 2.34129 20.2251 2.62499ZM7.00195 15.6278V8.76444C7.00838 8.40464 7.15562 8.06174 7.41197 7.80957C7.66833 7.5574 8.01331 7.4161 8.37265 7.4161C8.73199 7.4161 9.07697 7.5574 9.33332 7.80957C9.58968 8.06174 9.73692 8.40464 9.74334 8.76444V12.3334L14.6524 7.40515C15.1893 6.86607 16.0617 6.86553 16.5993 7.40394C17.1351 7.94044 17.1367 8.80862 16.6011 9.34523C14.8779 11.0716 12.3057 13.638 11.6623 14.2551H15.2261C15.5854 14.2616 15.9278 14.409 16.1796 14.6658C16.4314 14.9225 16.5725 15.268 16.5725 15.6278C16.5725 15.9877 16.4314 16.3331 16.1796 16.5899C15.9278 16.8466 15.5854 16.9941 15.2261 17.0005H8.37265C8.00945 16.9994 7.66144 16.8544 7.40462 16.5972C7.1478 16.34 7.00304 15.9915 7.00195 15.6278Z"
                  />
                  <path
                    id="shape_2"
                    d="M7.00195 8.76395V15.6273C7.00304 15.991 7.1478 16.3396 7.40462 16.5968C7.66144 16.8539 8.00945 16.9989 8.37265 17H15.2261C15.5854 16.9936 15.9278 16.8461 16.1796 16.5894C16.4314 16.3327 16.5725 15.9872 16.5725 15.6273C16.5725 15.2675 16.4314 14.922 16.1796 14.6653C15.9278 14.4085 15.5854 14.2611 15.2261 14.2547H11.6623C12.3057 13.6375 14.8779 11.0711 16.6011 9.34475C17.1367 8.80813 17.1351 7.93995 16.5993 7.40346C16.0617 6.86504 15.1893 6.86558 14.6524 7.40466L9.74334 12.3329V8.76395C9.73692 8.40415 9.58968 8.06126 9.33332 7.80908C9.07697 7.55691 8.73199 7.41562 8.37265 7.41562C8.01331 7.41562 7.66833 7.55691 7.41197 7.80908C7.15562 8.06126 7.00838 8.40415 7.00195 8.76395Z"
                  />
                </svg>
                <span>Выводы средств</span>
              </span>
            </NavLink>
          </li>
          <li className="mb-4">
            <NavLink to="finance/deals" disabled>
              <span className="d-flex flex-row align-items-center">
                <svg
                  className="icon"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    id="synch"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M3.51342 21.6754C2.64214 21.4899 2.06118 20.7885 1.96967 19.9025C1.83399 18.5886 1.67969 16.1764 1.67969 11.9999C1.67969 7.82348 1.83399 5.41123 1.96967 4.0974C2.06118 3.2113 2.64214 2.50991 3.51342 2.32442C4.88869 2.03165 7.48118 1.67993 11.9997 1.67993C16.5182 1.67993 19.1107 2.03165 20.4859 2.32442C21.3572 2.50991 21.9382 3.2113 22.0297 4.0974C22.1654 5.41123 22.3197 7.82348 22.3197 11.9999C22.3197 16.1764 22.1654 18.5886 22.0297 19.9025C21.9382 20.7885 21.3572 21.4899 20.486 21.6754C19.1107 21.9682 16.5182 22.3199 11.9997 22.3199C7.48118 22.3199 4.88869 21.9682 3.51342 21.6754ZM13.09 9.92633V5.20433C13.0892 4.95409 12.9896 4.71431 12.8129 4.53736C12.6362 4.36042 12.3968 4.26068 12.1469 4.25993H7.43172C7.18453 4.26436 6.94897 4.36581 6.77572 4.54243C6.60248 4.71906 6.5054 4.95675 6.5054 5.20433C6.5054 5.45192 6.60248 5.6896 6.77572 5.86623C6.94897 6.04286 7.18453 6.1443 7.43172 6.14873H9.88362C9.44095 6.57331 7.67129 8.33899 6.48576 9.52675C6.11726 9.89594 6.11838 10.4932 6.48695 10.8624C6.85684 11.2328 7.45704 11.2324 7.82647 10.8615L11.2039 7.47089V9.92633C11.2083 10.1739 11.3096 10.4098 11.486 10.5833C11.6623 10.7568 11.8997 10.854 12.1469 10.854C12.3941 10.854 12.6315 10.7568 12.8079 10.5833C12.9842 10.4098 13.0855 10.1739 13.09 9.92633ZM11.198 18.7955V14.0735C11.2024 13.826 11.3037 13.5901 11.48 13.4166C11.6564 13.2431 11.8938 13.1459 12.141 13.1459C12.3882 13.1459 12.6256 13.2431 12.8019 13.4166C12.9783 13.5901 13.0796 13.826 13.084 14.0735V16.529L16.4614 13.1383C16.8309 12.7675 17.4311 12.7671 17.801 13.1375C18.1695 13.5066 18.1706 14.1039 17.8021 14.4731C16.6166 15.6609 14.847 17.4265 14.4043 17.8511H16.8562C17.1034 17.8556 17.3389 17.957 17.5122 18.1336C17.6854 18.3103 17.7825 18.5479 17.7825 18.7955C17.7825 19.0431 17.6854 19.2808 17.5122 19.4574C17.3389 19.6341 17.1034 19.7355 16.8562 19.7399H12.141C11.8911 19.7392 11.6517 19.6394 11.475 19.4625C11.2983 19.2855 11.1987 19.0458 11.198 18.7955Z"
                  />
                </svg>
                <span>Сделки и комиссии</span>
              </span>
            </NavLink>
          </li>
          <li>
            <p className="nav-label">Обратная связь</p>
          </li>
          {/* <li>
            <NavLink to="modules">
              <span className="d-flex flex-row align-items-center">
                <svg
                  className="icon"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M3.51374 21.6755C2.64245 21.49 2.06149 20.7886 1.96998 19.9025C1.8343 18.5887 1.68 16.1764 1.68 12C1.68 7.82354 1.8343 5.41129 1.96998 4.09746C2.06149 3.21136 2.64245 2.50997 3.51374 2.32448C4.889 2.03171 7.48149 1.67999 12 1.67999C16.5185 1.67999 19.111 2.03171 20.4862 2.32448C21.3576 2.50997 21.9385 3.21136 22.03 4.09746C22.1657 5.41129 22.32 7.82354 22.32 12C22.32 16.1764 22.1657 18.5887 22.03 19.9025C21.9385 20.7886 21.3576 21.49 20.4863 21.6755C19.111 21.9683 16.5185 22.32 12 22.32C7.48149 22.32 4.889 21.9683 3.51374 21.6755ZM13.0903 9.92639V5.20439C13.0895 4.95415 12.9899 4.71437 12.8132 4.53743C12.6365 4.36048 12.3971 4.26074 12.1472 4.25999H7.43203C7.18485 4.26442 6.94928 4.36587 6.77603 4.54249C6.60279 4.71912 6.50572 4.95681 6.50572 5.20439C6.50572 5.45198 6.60279 5.68966 6.77603 5.86629C6.94928 6.04292 7.18485 6.14436 7.43203 6.14879H9.88393C9.44126 6.57338 7.6716 8.33905 6.48607 9.52681C6.11757 9.896 6.11869 10.4933 6.48727 10.8624C6.85716 11.2328 7.45735 11.2325 7.82679 10.8616L11.2042 7.47095V9.92639C11.2086 10.1739 11.3099 10.4098 11.4863 10.5833C11.6627 10.7568 11.9 10.854 12.1472 10.854C12.3945 10.854 12.6318 10.7568 12.8082 10.5833C12.9845 10.4098 13.0858 10.1739 13.0903 9.92639ZM11.1983 18.7956V14.0736C11.2027 13.826 11.304 13.5901 11.4804 13.4166C11.6567 13.2431 11.8941 13.1459 12.1413 13.1459C12.3885 13.1459 12.6259 13.2431 12.8022 13.4166C12.9786 13.5901 13.0799 13.826 13.0843 14.0736V16.529L16.4617 13.1384C16.8312 12.7675 17.4314 12.7671 17.8013 13.1376C18.1698 13.5067 18.171 14.104 17.8025 14.4732C16.6169 15.6609 14.8473 17.4266 14.4046 17.8512H16.8565C17.1037 17.8556 17.3393 17.9571 17.5125 18.1337C17.6857 18.3103 17.7828 18.548 17.7828 18.7956C17.7828 19.0432 17.6857 19.2809 17.5125 19.4575C17.3393 19.6341 17.1037 19.7356 16.8565 19.74H12.1413C11.8914 19.7392 11.652 19.6395 11.4753 19.4626C11.2986 19.2856 11.199 19.0458 11.1983 18.7956Z"
                  />
                </svg>
                <span>Маркет</span>
              </span>
            </NavLink>
          </li> */}
          <li>
            <NavLink to="dialogs" disabled>
              <span className="d-flex flex-row align-items-center">
                <svg
                  className="icon"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.32"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M4.12319 2.24369C5.3177 2.12083 7.30475 2 10.5 2C13.6952 2 15.6823 2.12083 16.8768 2.24369C17.8972 2.34863 18.6398 3.10549 18.7572 4.12444C18.8797 5.18775 19 6.7933 19 9C19 11.2067 18.8797 12.8123 18.7572 13.8756C18.6398 14.8945 17.8973 15.6514 16.877 15.7563C15.822 15.8648 14.1489 15.9717 11.571 15.9952C11.1714 15.9989 10.7875 16.1592 10.507 16.4437L8.816 18.1584C8.08185 18.9029 6.8125 18.4707 6.6853 17.4328L6.55145 16.3414C6.52175 16.099 6.3197 15.9137 6.0759 15.9011C5.26545 15.859 4.62502 15.8079 4.12397 15.7564C3.10365 15.6515 2.36022 14.8945 2.24278 13.8756C2.12023 12.8123 2 11.2067 2 9C2 6.7933 2.12023 5.18775 2.24278 4.12444C2.36022 3.10549 3.10287 2.34863 4.12319 2.24369ZM7 6C6.4477 6 6 6.4477 6 7C6 7.5523 6.4477 8 7 8H14C14.5523 8 15 7.5523 15 7C15 6.4477 14.5523 6 14 6H7ZM7 10.5C6.4477 10.5 6 10.9477 6 11.5C6 12.0523 6.4477 12.5 7 12.5H11C11.5523 12.5 12 12.0523 12 11.5C12 10.9477 11.5523 10.5 11 10.5H7Z"
                  />
                  <path d="M5.99902 7C5.99902 6.4477 6.44672 6 6.99902 6H13.999C14.5513 6 14.999 6.4477 14.999 7C14.999 7.5523 14.5513 8 13.999 8H6.99902C6.44672 8 5.99902 7.5523 5.99902 7Z" />
                  <path d="M5.99902 11.5C5.99902 10.9477 6.44672 10.5 6.99902 10.5H10.999C11.5513 10.5 11.999 10.9477 11.999 11.5C11.999 12.0523 11.5513 12.5 10.999 12.5H6.99902C6.44672 12.5 5.99902 12.0523 5.99902 11.5Z" />
                  <path d="M10.0257 16.9308L10.506 16.4437C10.7866 16.1592 11.1705 15.9989 11.57 15.9952C14.1479 15.9717 15.821 15.8648 16.876 15.7563C17.8963 15.6514 18.6388 14.8945 18.7562 13.8755C18.8363 13.1809 18.9154 12.2547 18.9606 11.0808C19.4674 11.1109 19.8664 11.1462 20.1773 11.1811C20.8028 11.2515 21.2614 11.7033 21.3392 12.3279C21.4206 12.9808 21.499 13.9995 21.499 15.5C21.499 17.0005 21.4206 18.0192 21.3392 18.6721C21.2614 19.2967 20.8021 19.7486 20.1766 19.8189C19.7585 19.8659 19.1808 19.9136 18.3962 19.9483C18.1641 19.9585 17.9673 20.1252 17.9223 20.3531L17.7516 21.219C17.6396 21.7868 16.9538 22.0192 16.5197 21.6364L15.0712 20.3589C14.8029 20.1223 14.4575 19.9901 14.0999 19.9816C12.7849 19.9504 11.9009 19.884 11.3222 19.819C10.6967 19.7488 10.2366 19.2967 10.1588 18.6721C10.1066 18.2528 10.0555 17.6826 10.0257 16.9308Z" />
                </svg>
                <span>Чат</span>
              </span>
              <Badge className="ms-2" bg="danger">
                8
              </Badge>
            </NavLink>
          </li>
          <li>
            <NavLink to="dialogs" disabled>
              <span className="d-flex flex-row align-items-center">
                <svg
                  className="icon"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.32"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M12 22.5C17.799 22.5 22.5 17.799 22.5 12C22.5 6.20101 17.799 1.5 12 1.5C6.20101 1.5 1.5 6.20101 1.5 12C1.5 17.799 6.20101 22.5 12 22.5ZM12 18.3C15.4794 18.3 18.3 15.4794 18.3 12C18.3 8.52061 15.4794 5.7 12 5.7C8.52061 5.7 5.7 8.52061 5.7 12C5.7 15.4794 8.52061 18.3 12 18.3Z"
                  />
                  <path d="M18.6038 3.01136C19.2189 2.39628 20.2161 2.39628 20.8312 3.01136C21.4463 3.62643 21.4463 4.62367 20.8312 5.23874L5.23947 20.8304C4.6244 21.4455 3.62716 21.4455 3.01209 20.8304C2.39701 20.2154 2.39701 19.2181 3.01209 18.6031L18.6038 3.01136Z" />
                </svg>

                <span>Стоп-слова</span>
              </span>
            </NavLink>
          </li>
          {/* 
               <Accordion>
            <Accordion.Item eventKey="2">
              <Accordion.Header>
                <span className="d-flex flex-row align-items-center">
                  <svg
                    className="icon"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M6.5565 2.10053C3.94224 2.2122 2 3.88336 2 6.5C2 9.11665 3.94224 10.7878 6.5565 10.8995C7.9246 10.9579 9.7062 11 12 11C14.2938 11 16.0754 10.9579 17.4435 10.8995C20.0577 10.7878 22 9.11665 22 6.5C22 3.88336 20.0577 2.2122 17.4435 2.10053C16.0754 2.04209 14.2938 2 12 2C9.7062 2 7.9246 2.04209 6.5565 2.10053ZM20 6.5C20 7.88071 18.8807 9 17.5 9C16.1193 9 15 7.88071 15 6.5C15 5.11929 16.1193 4 17.5 4C18.8807 4 20 5.11929 20 6.5Z"
                    />
                    <path
                      opacity="0.32"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M17.4435 13.1005C20.0577 13.2122 22 14.8833 22 17.5C22 20.1167 20.0577 21.7878 17.4435 21.8995C16.0754 21.9579 14.2938 22 12 22C9.7062 22 7.9246 21.9579 6.5565 21.8995C3.94224 21.7878 2 20.1167 2 17.5C2 14.8833 3.94224 13.2122 6.5565 13.1005C7.9246 13.0421 9.7062 13 12 13C14.2938 13 16.0754 13.0421 17.4435 13.1005ZM4 17.5C4 18.8807 5.11929 20 6.5 20C7.88071 20 9 18.8807 9 17.5C9 16.1193 7.88071 15 6.5 15C5.11929 15 4 16.1193 4 17.5Z"
                    />
                    <path
                      opacity="0.32"
                      d="M17.5 9C18.8807 9 20 7.88071 20 6.5C20 5.11929 18.8807 4 17.5 4C16.1193 4 15 5.11929 15 6.5C15 7.88071 16.1193 9 17.5 9Z"
                    />
                    <path d="M6.5 20C5.11929 20 4 18.8807 4 17.5C4 16.1193 5.11929 15 6.5 15C7.88071 15 9 16.1193 9 17.5C9 18.8807 7.88071 20 6.5 20Z" />
                  </svg>
                  <span>Настройки</span>
                </span>
              </Accordion.Header>
              <Accordion.Collapse eventKey="2">
                <div>
                  <li>
                    <NavLink
                      to="options/brands"
                      className="d-flex flex-row align-items-center"
                    >
                      Бренды
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="options/documents"
                      className="d-flex flex-row align-items-center"
                    >
                      Документы
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="affiliates"
                      className="d-flex flex-row align-items-center"
                    >
                      Филиалы
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="zones"
                      className="d-flex flex-row align-items-center"
                    >
                      Зоны доставки
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="members"
                      className="d-flex flex-row align-items-center"
                    >
                      Сотрудники
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="statuses"
                      className="d-flex flex-row align-items-center"
                    >
                      Статусы
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="marks"
                      className="d-flex flex-row align-items-center"
                    >
                      Метки
                    </NavLink>
                  </li>
                </div>
              </Accordion.Collapse>
            </Accordion.Item>
              </Accordion>
            */}
        </ul>
        <Footer />
      </nav>
    </div>
  );
};

export default AdminMenu;
