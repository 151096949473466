import moment from "moment";
import React from "react";
import { IoCheckmarkDoneOutline, IoCheckmarkOutline } from "react-icons/io5";

const Message = ({ my, time, text, name, view = false }) => {
  time = time
    ? moment(time).format("DD MMM YYYY kk:mm")
    : moment().format("DD MMM kk:mm");

  return my ? (
    <div className="d-flex align-self-end mb-3">
      <div className="text my">
        <time className="fs-08 text-muted d-flex align-items-center me-2">
          {time}
          {view ? (
            <IoCheckmarkDoneOutline className="text-success ms-1" size={15} />
          ) : (
            <IoCheckmarkOutline className="ms-1" size={15} />
          )}
        </time>
        <p>{text}</p>
      </div>
    </div>
  ) : (
    <div className="d-flex mb-3">
      <div className="pe-3">
        <img src="/images/avatar-full.png" alt={name} width={40} height={40} />
      </div>
      <div className="text">
        <div className="d-flex align-items-center justify-content-between">
          <div>
            <a className="fw-6">{name}</a>
          </div>
          <div>
            <time className="fs-08 text-muted ms-2">{time}</time>
          </div>
        </div>
        <p>{text}</p>
      </div>
    </div>
  );
};

export default Message;
