import moment from "moment";
import React, { useCallback, useLayoutEffect, useMemo, useState } from "react";
import { Card, Col, Form, Row } from "react-bootstrap";
import { IoCreateOutline, IoTrashOutline } from "react-icons/io5";
import { Link, useSearchParams } from "react-router-dom";
// import notification1 from "../../assets/audio/notification-1.mp3";
import { useSelector } from "react-redux";
import DataTable from "../../components/DataTable";
import Meta from "../../components/Meta";
import Status from "../../components/StatusOrder";
import Button from "../../components/UI/Button";
import Info from "../../components/UI/Info";
import Loader from "../../components/UI/Loader";
import Select from "../../components/UI/Select";
import CustomModal from "../../components/utils/CustomModal";
import socket from "../../config/socket";
import { getImageURL } from "../../helpers/image";
import { deliveryData, paymentData } from "../../helpers/order";
import { customPrice } from "../../helpers/product";
import { deleteOrder, getOrders } from "../../services/order";
import { getStatistics } from "../../services/statistic";

const Orders = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  // const auth = useSelector((state) => state.auth);
  const brand = useSelector((state) => state.brand);

  const [orders, setOrders] = useState({
    loading: true,
    items: [],
  });

  const [selected, setSelected] = useState([]);
  const [modalDelete, setModalDelete] = useState({
    isShow: false,
    id: false,
  });

  const [statistic, setStatistic] = useState({
    order: 0,
    total: 0,
    average: 0,
  });

  // useEffect(() => {
  //   const audio = new Audio(notification1);
  //   audio.play();
  //   let link = document.querySelector("link[rel~='icon']");
  //   if (!link) {
  //     link = document.createElement("link");
  //     link.rel = "icon";
  //     document.getElementsByTagName("head")[0].appendChild(link);
  //   }
  //   let num = 0;
  //   let animation = setInterval(function () {
  //     if (num == 1) {
  //       num = 0;
  //       link.href = "/logo.svg";
  //     } else {
  //       num = 1;
  //       link.href = "/logo-notification.png";
  //     }
  //   }, 1000);
  //   return () => clearInterval(animation);
  // }, []);

  const orderColumns = [
    {
      name: "Товары",
      selector: "products",
      width: "90px",
      openDesc: (row) => (
        <Row className="fs-09 p-2 desc align-items-start">
          <Col md={8} className="py-0">
            <Row>
              {row.products &&
                row.products.map((e) => (
                  <Col md={4} className="order-product pb-3">
                    <div className="d-flex align-items-center">
                      <div className="pe-2">
                        <img
                          src={getImageURL(e.medias)}
                          width={35}
                          height={35}
                        />
                      </div>
                      <div>
                        <p className="fs-09">
                          <span className="text-success">{e.count}х</span>{" "}
                          {e.title}
                        </p>
                        <p className="fw-6 fs-09">
                          {customPrice(
                            e.price > 0 ? e.price : e.modifiers.price ?? 0
                          )}
                        </p>
                      </div>
                    </div>
                  </Col>
                ))}
            </Row>
            {row.comment && (
              <div className="comment">
                <p className="text-muted fs-08">Комментарий</p>
                {row.comment}
              </div>
            )}
          </Col>
          <Col md={4} className="flex-column">
            <p className="mb-2">
              <span className="text-muted me-2">Заказчик:</span>
              {row.firstName ?? "Не указано"}
            </p>

            {row.person > 0 && (
              <p className="mb-2">
                <span className="text-muted me-2">Кол-во персон:</span>
                {row.person}
              </p>
            )}

            <p className="mb-2">
              <span className="text-muted me-2">Ко времени:</span>
              {row.serving ? (
                moment(row.serving).format("DD.MM.YYYY kk:mm")
              ) : (
                <span className="text-success">Сейчас</span>
              )}
            </p>

            {row.delivery === "delivery" ? (
              <p>
                <span className="text-muted me-2">Доставка:</span>
                {row.street} {row.home}
              </p>
            ) : (
              <p>
                <span className="text-muted me-2">Самовывоз:</span>
                {row.street} {row.home}
              </p>
            )}
          </Col>
        </Row>
      ),
      cell: (row) => `${row?.products?.length ?? 0} шт`,
    },
    {
      name: "Время заказа",
      selector: "createdAt",
      cell: (row) => moment(row.createdAt).format("DD MMM YYYY kk:mm"),
    },
    {
      name: "Номер телефона",
      selector: "phone",
      cell: (row) => <a href={"tel:" + row.phone}>{row.phone}</a>,
    },
    {
      name: "Способ доставки",
      selector: "deliveryType",
      cell: (row) => deliveryData(row.delivery).text,
    },
    {
      name: "Сотрудник",
      selector: "delivery",
      cell: (row) =>
        row?.noteCount ? (
          <>
            Сотрудник <span class="ms-1 badge bg-danger">{row.noteCount}</span>
          </>
        ) : (
          "Сотрудник"
        ),
    },
    {
      name: "Итого",
      width: "100px",
      align: "right",
      selector: "total",
      cell: (row) => (
        <div className="d-flex align-items-center">
          <span>{customPrice(row.total)}</span>
          <img className="ms-1" src={paymentData(row.payment).icon} />
        </div>
      ),
    },
    {
      name: "Статус",
      selector: "status",
      cell: (row) => <Status data={row} />,
    },
    {
      width: "35px",
      selector: "action",
      align: "right",
      cell: (row) => (
        <Link to={"/order/" + row.id}>
          <IoCreateOutline size={22} />
        </Link>
      ),
    },
  ];

  const header = useMemo(() => {
    return selected.length > 0 ? (
      <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center">
          <h5 className="fw-7 me-3">Выбрано {selected.length}</h5>
        </div>
        <div className="d-flex align-items-center">
          <div>
            <Button className="btn-light">
              <IoTrashOutline size={18} />
            </Button>
          </div>
        </div>
      </div>
    ) : (
      <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center">
          <h5 className="fw-7 me-3">Заказы</h5>
          <Button className="btn-primary-outline">Добавить заказ</Button>
        </div>
        {/* <div className="d-flex align-items-center">
          <Form.Control placeholder="Поиск..." className="me-3" />
          <div>
            <Button className="btn-primary-outline">Статистика</Button>
          </div>
        </div> */}
      </div>
    );
  }, [selected]);

  const getData = useCallback(async () => {
    getOrders(searchParams)
      .then(
        (res) =>
          res &&
          setOrders((prev) => ({
            ...prev,
            loading: false,
            ...res,
          }))
      )
      .finally(() => setOrders((prev) => ({ ...prev, loading: false })));
  }, [searchParams]);

  useLayoutEffect(() => {
    socket.on("orders-panel/" + brand.active.id, (data) => {
      if (data?.statuses?.length > 0) {
        let newOrders = orders.items.map((e) => {
          if (e.id === data.id) {
            return data;
          }
          return e;
        });

        if (newOrders) {
          setOrders((e) => ({ ...e, items: newOrders }));
        }
      }
    });
    socket.on("notes/" + brand.active.id, (data) => {
      if (data?.orderId) {
        let newOrders = orders.items.map((e) => {
          if (e.id === data.orderId) {
            e.noteCount = e?.noteCount ? e.noteCount + 1 : 1;
          }
          return e;
        });
        if (newOrders) {
          setOrders((e) => ({ ...e, items: newOrders }));
        }
      }
    });
    return () => {
      socket.off("orders-panel/" + brand.active.id);
      socket.off("notes/" + brand.active.id);
    };
  }, [orders.items]);

  useLayoutEffect(() => {
    getStatistics({ order: true })
      .then((res) =>
        setStatistic((prev) => ({ ...prev, ...res, loading: false }))
      )
      .catch(() => setStatistic((prev) => ({ ...prev, loading: false })));
  }, [searchParams.get("page")]);

  useLayoutEffect(() => {
    getData();
  }, [searchParams.get("page")]);

  const clickDelete = (id) => {
    deleteOrder(id).then(() => getData());
    setModalDelete({ isShow: false, id: false });
  };

  if (orders.loading) {
    return <Loader full />;
  }

  if (!orders?.items || orders?.items?.length === 0) {
    return (
      <>
        <Meta title="Заказы" />
        <Info>
          <svg
            className="mb-3"
            width="60"
            height="60"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.32"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12 22.5C17.799 22.5 22.5 17.799 22.5 12C22.5 6.20101 17.799 1.5 12 1.5C6.20101 1.5 1.5 6.20101 1.5 12C1.5 17.799 6.20101 22.5 12 22.5ZM12 18.3C15.4794 18.3 18.3 15.4794 18.3 12C18.3 8.52061 15.4794 5.7 12 5.7C8.52061 5.7 5.7 8.52061 5.7 12C5.7 15.4794 8.52061 18.3 12 18.3Z"
              fill="#999"
            />
            <path
              d="M18.6028 3.01136C19.2179 2.39628 20.2151 2.39628 20.8302 3.01136C21.4453 3.62643 21.4453 4.62367 20.8302 5.23874L5.2385 20.8304C4.62342 21.4455 3.62619 21.4455 3.01111 20.8304C2.39604 20.2154 2.39604 19.2181 3.01111 18.6031L18.6028 3.01136Z"
              fill="#999"
            />
          </svg>
          <h3>Пока заказов нет</h3>
        </Info>
      </>
    );
  }

  return (
    <>
      <Meta title="Заказы" />
      <Row className="mb-4">
        <Col md={4}>
          <Card body>
            <h5 className="mb-2 fw-6 h6">Кол-во заказов</h5>
            <div className="d-flex align-items-center justify-content-between">
              <div>
                <h2 className="m-0">{customPrice(statistic.order, false)}</h2>
                <div className="mt-2">
                  <img src="/images/stat-plus.svg" />{" "}
                  <small className="fw-6">
                    +11.3% <span className="light-gray">за день</span>
                  </small>
                </div>
              </div>
              <div>
                <img src="/images/stat-1.svg" height={35} />
              </div>
            </div>
          </Card>
        </Col>
        <Col md={4}>
          <Card body>
            <h5 className="mb-2 fw-6 h6">Выручка</h5>
            <Select
              title="Выберите период"
              className="fs-09"
              data={[
                {
                  title: "Месяц",
                  value: "month",
                },
                {
                  title: "День",
                  value: "day",
                },
                {
                  title: "Неделя",
                  value: "weekend",
                },
                {
                  title: "Задать период",
                  value: "other",
                },
              ]}
            />
            <div className="mt-2 d-flex align-items-end">
              <h3 className="m-0 me-2 fw-7 h6">
                {customPrice(statistic.total)}
              </h3>
              <span className="fs-09 text-muted">за месяц</span>
            </div>
          </Card>
        </Col>
        <Col md={4}>
          <Card body>
            <h5 className="mb-2 fw-6 h6">Средний чек</h5>
            <div className="d-flex align-items-center justify-content-between">
              <div>
                <h2 className="m-0">{customPrice(statistic.average)}</h2>
                <div className="mt-2">
                  <img src="/images/stat-plus.svg" />{" "}
                  <small className="fw-6">
                    +11.3% <span className="light-gray">за день</span>
                  </small>
                </div>
              </div>
              <div>
                <img src="/images/stat-2.svg" height={55} />
              </div>
            </div>
          </Card>
        </Col>
      </Row>
      <DataTable
        columns={orderColumns}
        onChange={(items) => setSelected(items)}
        data={orders.items}
        header={header}
        selectable
        linkPagination="/orders/"
        statusBackground="order"
        pagination={orders.pagination}
      />
      <CustomModal
        title={`Удаление ${modalDelete.id ? "#" + modalDelete.id : ""}`}
        isShow={modalDelete.isShow}
        setShow={(e) => setModalDelete({ isShow: e, id: false })}
        footer={
          <>
            <Button
              className=" me-3"
              onClick={(e) =>
                setModalDelete({ isShow: !modalDelete.isShow, id: false })
              }
            >
              Отмена
            </Button>
            <Button
              className="btn-primary"
              onClick={() => modalDelete.id && clickDelete(modalDelete.id)}
            >
              Удалить
            </Button>
          </>
        }
      >
        Вы точно хотите удалить заказ?
      </CustomModal>
    </>
  );
};

export default Orders;
