import { $authApi } from ".";
import { apiRoutes } from "../config/api";

const getCategories = async (data) => {
  const response = await $authApi.get(apiRoutes.CATEGORIES, {
    params: {
      ...data,
      type: "noProducts",
    },
  });

  return response?.data;
};

const getCategory = async (id) => {
  const response = await $authApi.get(apiRoutes.CATEGORY, {
    params: {
      id,
    },
  });

  return response?.data;
};

const createCategory = async (data) => {
  const response = await $authApi.post(apiRoutes.CATEGORIES, data);
  return response?.data;
};

const editCategory = async (data) => {
  const response = await $authApi.put(apiRoutes.CATEGORIES, data);
  return response?.data;
};

const deleteCategory = async (ids) => {
  const response = await $authApi.delete(apiRoutes.CATEGORIES, {
    data: { ids },
  });
  return response?.data;
};

export {
  getCategories,
  getCategory,
  createCategory,
  editCategory,
  deleteCategory,
};
