import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
};

const affiliateSlice = createSlice({
  name: "affiliate",
  initialState,
  reducers: {
    setAffiliates: (state, action) => {
      state.data = action.payload;
    },
    mainAffiliateEdit: (state, action) => {
      state.data = state.data.map((e) => {
        e.main = e.id === action?.payload;
        return e;
      });
    },
  },
});

export const { setAffiliates, mainAffiliateEdit } = affiliateSlice.actions;

export default affiliateSlice.reducer;
