import React, { useCallback, useLayoutEffect, useMemo, useState } from "react";
import { Col, Dropdown, Row } from "react-bootstrap";
import {
  IoEllipsisVertical,
  IoSearchOutline,
  IoTrashOutline,
} from "react-icons/io5";
import { useSelector } from "react-redux";
import { Link, useSearchParams } from "react-router-dom";
import DataTable from "../../components/DataTable";
import Meta from "../../components/Meta";
import Online from "../../components/Online";
import Button from "../../components/UI/Button";
import Info from "../../components/UI/Info";
import Loader from "../../components/UI/Loader";
import CustomModal from "../../components/utils/CustomModal";
import socket from "../../config/socket";
import { convertColor } from "../../helpers/convertColor";
import { getImageURL } from "../../helpers/image";
import { isRole } from "../../helpers/member";
import { deleteMember, getMembers } from "../../services/member";
import Input from "../../components/UI/Input";

const Members = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [members, setMembers] = useState({
    loading: true,
    items: [],
  });

  const [selected, setSelected] = useState([]);
  const [modalDelete, setModalDelete] = useState({
    isShow: false,
    id: false,
  });

  const memberColumns = [
    {
      name: "Ник",
      selector: "nickname",
      cell: (row) => (
        <Link to={"/member/" + row.id} className="d-flex align-items-center">
          <div className="position-relative me-2">
            {(row.status === 0 || row.blockedEnd) && (
              <div className="blocked-avatar">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.32"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    fill="#FF0000"
                    d="M12 22.5C17.799 22.5 22.5 17.799 22.5 12C22.5 6.20101 17.799 1.5 12 1.5C6.20101 1.5 1.5 6.20101 1.5 12C1.5 17.799 6.20101 22.5 12 22.5ZM12 18.3C15.4794 18.3 18.3 15.4794 18.3 12C18.3 8.52061 15.4794 5.7 12 5.7C8.52061 5.7 5.7 8.52061 5.7 12C5.7 15.4794 8.52061 18.3 12 18.3Z"
                  />
                  <path
                    fill="#FF0000"
                    d="M18.6038 3.01136C19.2189 2.39628 20.2161 2.39628 20.8312 3.01136C21.4463 3.62643 21.4463 4.62367 20.8312 5.23874L5.23947 20.8304C4.6244 21.4455 3.62716 21.4455 3.01209 20.8304C2.39701 20.2154 2.39701 19.2181 3.01209 18.6031L18.6038 3.01136Z"
                  />
                </svg>
              </div>
            )}
            {row?.options?.verified && (
              <IoCheckmarkCircle size={15} className="avatar-verified" />
            )}
            <img
              src={getImageURL({ path: row.media, type: "user" })}
              width={30}
              height={30}
              className="mini-img"
            />
          </div>
          <div>
            {row.firstName}
            <Online data={row} />
          </div>
        </Link>
      ),
    },
    {
      name: "Номер телефона",
      selector: "phone",
    },
    {
      name: "Email",
      selector: "email",
    },
    {
      name: "Должность",
      selector: "role",
      cell: (row) => {
        let role = isRole(row);
        return role?.role?.title ?? "Сотрудник";
      },
    },
    {
      name: "Отдел",
      selector: "groupRole",
      cell: (row) => {
        let role = isRole(row);
        return (
          <div
            className="role"
            style={
              role?.group?.color && {
                color: role.group.color,
                backgroundColor: convertColor(role.group.color, 0.08),
              }
            }
          >
            {role?.group?.title ?? "Отдел не указан"}
          </div>
        );
      },
    },
    {
      width: "35px",
      selector: "action",
      align: "right",
      cell: (row) =>
        row.role >= 10 && (
          <Dropdown className="d-flex align-items-center">
            <Dropdown.Toggle
              as={React.forwardRef(({ children, onClick }, ref) => (
                <Link
                  ref={ref}
                  className="py-0"
                  onClick={(e) => {
                    e.preventDefault();
                    onClick(e);
                  }}
                >
                  <IoEllipsisVertical size={20} />
                </Link>
              ))}
            />
            <Dropdown.Menu align="end">
              <Dropdown.Item as={Link} to={"/member/" + row.id}>
                Редактировать
              </Dropdown.Item>
              <Dropdown.Item>Заблокировать</Dropdown.Item>
              <Dropdown.Item
                onClick={() =>
                  setModalDelete({
                    show: !modalDelete.show,
                    id: row.id,
                  })
                }
              >
                Удалить
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        ),
    },
  ];

  const getData = useCallback(async () => {
    getMembers(searchParams)
      .then((res) => {
        setMembers((prev) => ({
          ...prev,
          loading: false,
          ...res,
        }));
        socket.emit("member/list");
      })
      .catch(() => setMembers((prev) => ({ ...prev, loading: false })));
  }, [searchParams]);

  const onSearch = useCallback(() => {
    getData();
  }, [searchParams]);

  useLayoutEffect(() => {
    getData();
  }, [searchParams.get("page")]);

  const header = useMemo(() => {
    return (
      <>
        <div className="d-flex align-items-center justify-content-between">
          <div>
            <h5 className="fw-7">
              {selected.length > 0 ? `Выбрано ${selected.length}` : "Команда"}
            </h5>
          </div>
          <div>
            <Button
              disabled={selected.length === 0}
              className="btn-light"
              onClick={() => setModalDelete({ show: true, id: false })}
            >
              <IoTrashOutline size={18} />
            </Button>
          </div>
        </div>
        <div>
          <Row>
            <Col md={3}>
              <Link className="btn btn-primary w-100" to="/members/create">
                Добавить сотрудника
              </Link>
            </Col>
            <Col md={9}>
              <Input
                placeholder="Найти"
                className="w-100"
                onChange={(e) => {
                  searchParams.set("text", e);
                  setSearchParams(searchParams);
                }}
                rightIcon={() => <IoSearchOutline size={22} />}
                defaultValue={searchParams.get("text")}
                rightIconClick={() => onSearch()}
                onKeyDown={(e) => e === "Enter" && onSearch()}
              />
            </Col>
          </Row>
        </div>
      </>
    );
  }, [selected, searchParams, modalDelete]);

  useLayoutEffect(() => {
    socket.on("member/list", (data) => {
      if (Array.isArray(data)) {
        setMembers(({ items }) => ({
          loading: false,
          items: Array.isArray(items)
            ? items.map((e) => {
                let info = data.find(
                  (e2) => String(e2.memberId) === String(e.id)
                );

                e.online = !!info ? info?.end ?? "now" : false;

                return e;
              })
            : [],
        }));
      }
    });
    return () => {
      socket.off("member/list");
    };
  }, []);

  const clickDelete = (id) => {
    deleteMember(id).then(() => getData(page));
    setModalDelete({ isShow: false, id: false });
  };

  if (members.loading) {
    return <Loader full />;
  }

  if (!members.items || members.items.length === 0) {
    return (
      <Info>
        <svg
          className="mb-3"
          width="60"
          height="60"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.32"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 22.5C17.799 22.5 22.5 17.799 22.5 12C22.5 6.20101 17.799 1.5 12 1.5C6.20101 1.5 1.5 6.20101 1.5 12C1.5 17.799 6.20101 22.5 12 22.5ZM12 18.3C15.4794 18.3 18.3 15.4794 18.3 12C18.3 8.52061 15.4794 5.7 12 5.7C8.52061 5.7 5.7 8.52061 5.7 12C5.7 15.4794 8.52061 18.3 12 18.3Z"
            fill="#999"
          />
          <path
            d="M18.6028 3.01136C19.2179 2.39628 20.2151 2.39628 20.8302 3.01136C21.4453 3.62643 21.4453 4.62367 20.8302 5.23874L5.2385 20.8304C4.62342 21.4455 3.62619 21.4455 3.01111 20.8304C2.39604 20.2154 2.39604 19.2181 3.01111 18.6031L18.6028 3.01136Z"
            fill="#999"
          />
        </svg>
        <h3>Пока сотрудников нет</h3>
        <Link className="mt-3 btn btn-primary" to="/members/create">
          Добавить сотрудника
        </Link>
      </Info>
    );
  }

  return (
    <>
      <Meta title="Сотрудники" />
      <DataTable
        columns={memberColumns}
        onChange={(items) => setSelected(items)}
        data={members.items}
        header={header}
        selectable
        pagination={members.pagination}
      />
      <CustomModal
        title={`Удаление ${modalDelete.id ? "#" + modalDelete.id : ""}`}
        isShow={modalDelete.isShow}
        setShow={(e) => setModalDelete({ isShow: e, id: false })}
        footer={
          <>
            <Button
              className=" me-3"
              onClick={(e) =>
                setModalDelete({ isShow: !modalDelete.isShow, id: false })
              }
            >
              Отмена
            </Button>
            <Button
              className="btn-primary"
              onClick={() => modalDelete.id && clickDelete(modalDelete.id)}
            >
              Удалить
            </Button>
          </>
        }
      >
        Вы точно хотите удалить?
      </CustomModal>
    </>
  );
};

export default Members;
