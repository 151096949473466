import React from "react";
import { NavLink } from "react-router-dom";

const MenuCatalog = () => {
  return (
    <div className="w-100 account-menu-sub-bg">
      <nav className="account-menu-sub">
        <ul>
          {/* <li>
            <NavLink to="storages" end>
              Склад
            </NavLink>
          </li> */}
          <li>
            <NavLink to="categories" disabled>Игры</NavLink>
          </li>
          <li>
            <NavLink to="products" disabled>Объявления</NavLink>
          </li>
          {/* <li>
            <NavLink to="additions">Добавки</NavLink>
          </li> */}
          <li>
            <NavLink to="sales">Баннеры</NavLink>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default MenuCatalog;
