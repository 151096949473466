import moment from "moment/moment";
import React, { useCallback, useLayoutEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useForm, useWatch } from "react-hook-form";
import { IoKeyOutline } from "react-icons/io5";
import { NotificationManager } from "react-notifications";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Meta from "../../components/Meta";
import Button from "../../components/UI/Button";
import Info from "../../components/UI/Info";
import Input from "../../components/UI/Input";
import Loader from "../../components/UI/Loader";
import Select from "../../components/UI/Select";
import Textarea from "../../components/UI/Textarea";
import { convertColor } from "../../helpers/convertColor";
import { isRole, roles } from "../../helpers/member";
import { editMember, getMember } from "../../services/member";

const MemberEdit = () => {
  const { memberId } = useParams();
  const user = useSelector((state) => state.auth.user);
  const [loading, setLoading] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);

  const {
    control,
    register,
    formState: { errors, isValid },
    handleSubmit,
    setValue,
    reset,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onSubmit",
  });
  const form = useWatch({ control });

  const role = isRole(user);

  useLayoutEffect(() => {
    getMember(memberId)
      .then((res) => reset(res))
      .finally(() => setLoading(false));
  }, []);

  const onSubmit = useCallback((data) => {
    setBtnLoading(true);
    editMember(data)
      .then(() => NotificationManager.success("Данные успешно обновлены"))
      .catch((err) => err && NotificationManager.error("Ошибка при сохранении"))
      .finally(() => setBtnLoading(false));
  }, []);

  if (loading) {
    return <Loader full />;
  }

  if (!form?.id) {
    return (
      <Info>
        <svg
          className="mb-3"
          width="60"
          height="60"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.32"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 22.5C17.799 22.5 22.5 17.799 22.5 12C22.5 6.20101 17.799 1.5 12 1.5C6.20101 1.5 1.5 6.20101 1.5 12C1.5 17.799 6.20101 22.5 12 22.5ZM12 18.3C15.4794 18.3 18.3 15.4794 18.3 12C18.3 8.52061 15.4794 5.7 12 5.7C8.52061 5.7 5.7 8.52061 5.7 12C5.7 15.4794 8.52061 18.3 12 18.3Z"
            fill="#999"
          />
          <path
            d="M18.6028 3.01136C19.2179 2.39628 20.2151 2.39628 20.8302 3.01136C21.4453 3.62643 21.4453 4.62367 20.8302 5.23874L5.2385 20.8304C4.62342 21.4455 3.62619 21.4455 3.01111 20.8304C2.39604 20.2154 2.39604 19.2181 3.01111 18.6031L18.6028 3.01136Z"
            fill="#999"
          />
        </svg>
        <h3>Нет информации о сотруднике</h3>
      </Info>
    );
  }

  return (
    <>
      <Meta title="Редактирование сотрудника" />
      <h3 className="mb-4">Редактирование сотрудника</h3>
      <Row>
        <Col md={4}>
          <Card body>
            <div className="d-flex align-items-center justify-content-between">
              <div
                className="role"
                style={
                  role?.group?.color && {
                    color: role.group.color,
                    backgroundColor: convertColor(role.group.color, 0.08),
                  }
                }
              >
                {role?.group?.title ?? "Отдел не указан"}
              </div>
              <div>
                <IoKeyOutline size={22} className="text-muted" />
              </div>
            </div>
            <div className="mb-4 d-flex flex-column align-items-center position-relative">
              <img src="/images/avatar-full.png" height={110} />
              <p className="mt-2 fw-6 fs-08">
                {role?.role?.title ?? "Сотрудник"}{" "}
                <span className="fw-4 text-muted">
                  c {moment(user.createdAt).format("DD.MM.YYYY")}
                </span>
              </p>
            </div>
            <div className="mb-4">
              <Input
                label="Email"
                name="email"
                errors={errors}
                defaultValue={form.email}
                register={register}
              />
            </div>
            <div className="mb-4">
              <Input
                readOnly={false}
                label="Номер телефона"
                name="phone"
                mask="+7(999)999-99-99"
                errors={errors}
                defaultValue={form.phone}
                register={register}
                validation={{ required: "Обязательное поле" }}
              />
            </div>
            <Button
              className="btn btn-primary w-100"
              isValid={isValid}
              isLoading={btnLoading}
              disabled={btnLoading}
              onClick={handleSubmit(onSubmit)}
            >
              Сохранить изменения
            </Button>
          </Card>
        </Col>
        <Col md={8}>
          <Card body className="mb-4">
            {/* <h5 className="mb-4 fw-6 h6">Паспортные данные</h5>
            <Row>
              <Col md={4}>
                <div className="mb-4">
                  <Input
                    label="Фамилия"
                    name="lastName"
                    errors={errors}
                    register={register}
                    validation={{
                      maxLength: {
                        value: 50,
                        message: "Максимально 50 символов",
                      },
                    }}
                  />
                </div>
              </Col>
              <Col md={4}>
                <div className="mb-4">
                  <Input
                    label="Имя"
                    name="firstName"
                    errors={errors}
                    register={register}
                    validation={{
                      required: "Введите имя",
                      maxLength: {
                        value: 20,
                        message: "Максимально 20 символов",
                      },
                    }}
                  />
                </div>
              </Col>
              <Col md={4}>
                <div className="mb-4">
                  <Input
                    label="Отчество"
                    name="patronymic"
                    errors={errors}
                    register={register}
                    validation={{
                      maxLength: {
                        value: 50,
                        message: "Максимально 50 символов",
                      },
                    }}
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-4">
                  <Input
                    type="date"
                    label="День рождения"
                    name="brithday"
                    errors={errors}
                    register={register}
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-4">
                  <Input
                    type="text"
                    label="Номер паспорта"
                    name="passport.number"
                    errors={errors}
                    register={register}
                    validation={{
                      maxLength: {
                        value: 20,
                        message: "Максимально 20 символов",
                      },
                    }}
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-4">
                  <Input
                    type="date"
                    label="Дата выдачи"
                    name="passport.outputDate"
                    errors={errors}
                    register={register}
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-4">
                  <Input
                    type="text"
                    label="Код подразделения"
                    name="passport.code"
                    errors={errors}
                    register={register}
                    validation={{
                      maxLength: {
                        value: 20,
                        message: "Максимально 20 символов",
                      },
                    }}
                  />
                </div>
              </Col>
              <Col md={12}>
                <div className="mb-4">
                  <Textarea
                    label="Кем выдан"
                    name="passport.outputBy"
                    rows={1}
                    errors={errors}
                    register={register}
                    validation={{
                      maxLength: {
                        value: 500,
                        message: "Максимально 500 символов",
                      },
                    }}
                  />
                </div>
              </Col>
              <Col md={12}>
                <div className="mb-4">
                  <Textarea
                    label="Регистрация"
                    name="passport.addressRegistration"
                    rows={1}
                    errors={errors}
                    register={register}
                    validation={{
                      maxLength: {
                        value: 500,
                        message: "Максимально 500 символов",
                      },
                    }}
                  />
                </div>
              </Col>
            </Row> */}
            <h5 className="mb-4 fw-6 h6">Настройки</h5>
            <Row>
              <Col>
                <Select
                  className="mb-4"
                  label="Отдел"
                  onClick={(e) => setValue("groupRole", e.value)}
                  value={form.groupRole}
                  data={roles}
                />
              </Col>
              <Col>
                <Select
                  className="mb-4"
                  label="Должность"
                  onClick={(e) => setValue("role", e.value)}
                  value={form.role}
                  data={roles.find((e) => e.value === form.groupRole).data}
                />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default MemberEdit;
